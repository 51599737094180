/**
 * @file
 * Custom scripts for theme.
 */
(function ($) {
  Drupal.behaviors.hideEmptyPanes = {
    attach: function (context, settings) {
      $('#main-wrapper .panel-pane, .pane-pane-oa-form-actions', context).each(function () {
        // Look for empty panels that also don't contain input fields
        // Action Network embed widgets, images, or iframes
        // (input fields don't generate text()
        if ($(this).find('input,img,iframe,.geofieldMap,[id^="can"]').length == 0 && $(this).text().trim().length == 0) {
          $(this).css('display', 'none');
        }
      });
      // Check the edit panel at bottom AFTER we have done the above hiding
      $('.pane-oa-edit-minipanel .radix-layouts-content', context).each(function () {
        var text = $(this).find('.panel-pane:visible').text();
        if (text.trim().length == 0) {
          $(this).css('display', 'none');
        }
      });
      $('.oa-responsive-regions-toggle-left', context).each(function () {
        $(this).bind('click', function() {
          $('.navbar-tray').removeClass('active');
        });
      });
    }
  };
  Drupal.behaviors.mobileNavAccordions = {
    attach: function (context, settings) {
      // Accordionate main menu subnav on mobile
      $('.chapter-menu .pane-og-menu-single-menu .menu > li > .menu').each(function(){
        $(this).before('<span class="subnav-trigger">+</span>');
      });
      $('.subnav-trigger').each(function() {
        $(this).on('click', function() {
          $(this).siblings('ul.menu').toggleClass('subnav-show');
        });
      });
    }
  };
  Drupal.behaviors.fitVids = {
    attach: function (context, settings) {
      // Make videos resize responsively
      $('#main-wrapper').fitVids({
        customSelector: 'iframe[src*="facebook.com\/plugins\/video.php"]'
      });
    }
  };
})(jQuery);
